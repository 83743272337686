import { FC } from "react";

interface WarehouseReportProps {
  countTransferToManufacturer: number | null;
  countRepaired: number | null;
  countDisposableReceived: number | null;
  countDisposableRepair: number | null;
}

export const WarehouseReport: FC<WarehouseReportProps> = ({
  countTransferToManufacturer,
  countDisposableReceived,
  countDisposableRepair,
  countRepaired,
}) => {
  return (
    <table className="table table-bordered amount">
      <tbody>
        <tr>
          <th>Wysłane do producenta</th>
          <td>{countTransferToManufacturer}</td>
        </tr>
        <tr>
          <th>Ile odebranych jednorazowych</th>
          <td>{countDisposableReceived}</td>
        </tr>
        <tr>
          <th>Ile do naprawy jednorazowych</th>
          <td>{countDisposableRepair}</td>
        </tr>
        <tr>
          <th>Ile naprawionych EURO</th>
          <td>{countRepaired}</td>
        </tr>
      </tbody>
    </table>
  );
};
