import { FC } from "react";
import { TransferToReport } from "../../../interface/Report";

interface TransportToStatsProps {
  transportToStat: TransferToReport | null;
}
export const TransportToStats: FC<TransportToStatsProps> = ({ transportToStat }) => {
  
  return (
    <div className="table-responsive">
      <table className="table table-bordered transports">
        <thead>
          <tr>
            <th>Magazyn</th>
            <th>Ilość palet</th>
            <th>Ilość transportów</th>
          </tr>
        </thead>
        <tbody>
          {transportToStat &&
            transportToStat.storesStats.map((row) => (
                <tr key={row.storeName}>
                  <td className="text-right">{row.storeName}</td>
                  <td>
                    {row.amount || 0}
                  </td>
                  <td>
                    {row.count || 0}
                  </td>
                </tr>
              ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Suma:</td>
            <td>{transportToStat?.palletAmount}</td>
            <td>{transportToStat?.transportsCount}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
