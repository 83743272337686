import { FC, Fragment } from "react";
import { TransferFromReport } from "../../../interface/Report";
import { TransportType, TransportTypeName } from "../../../interface/Transfer";

interface TransportFromStatsProps {
  transportStat: TransferFromReport | null;
}
export const TransportFromStats: FC<TransportFromStatsProps> = ({
  transportStat,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered transports">
        <thead>
          <tr>
            <th></th>
            {Object.values(TransportType).map((transportType) => (
              <Fragment key={`head-${transportType}`}>
                <th colSpan={2}>Transportu {TransportTypeName[transportType]}</th>
              </Fragment>
            ))}
          </tr>
          <tr>
            <th>Magazyn</th>
            {Object.values(TransportType).map((transferType) => (
              <Fragment key={`head-amount-${transferType}`}>
                <th>Ilość palet</th>
                <th>Ilość transportów</th>
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {transportStat &&
            Object.keys(transportStat.storesStats).map((storeName, index) => (
              <tr key={index}>
                <td className="text-right">{storeName}</td>
                {Object.values(TransportType).map((transportType) => (
                  <Fragment key={`row-${transportType}`}>
                    <td>
                      {transportStat.storesStats[storeName][transportType]
                        ?.amount || 0}
                    </td>
                    <td>
                      {transportStat.storesStats[storeName][transportType]
                        ?.count || 0}
                    </td>
                  </Fragment>
                ))}
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Suma:</td>
            {Object.values(TransportType).map((transportType) => (
              <Fragment key={`foot-${transportType}`}>
                <td>{transportStat?.palletAmount[transportType]}</td>
                <td>{transportStat?.transportsCount[transportType]}</td>
              </Fragment>
            ))}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
