import { useContext } from "react";
import { MessageContext } from "../../../common/context/MessageContext";

export const Error = () => {
  const { errorMessage, clearErrorMessage } = useContext(MessageContext);
  return errorMessage ? (
    <div
      className="z-index-top alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      {errorMessage}
      <button
        onClick={clearErrorMessage}
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  ) : (
    <></>
  );
};
