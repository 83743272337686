import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { apiClient } from "../../apiClient/apiClient";
import { clearToken, hasRole as hasRoleAuth, saveToken } from "../../auth";
import { Roles, User, UserDTO } from "../../interface/User";

interface AuthContextValue {
  getUser: () => User | null;
  hasRole: (role: Roles) => boolean;
  loginUser: (token: string) => Promise<User>;
  loginOut: () => void;
}

const initValue = {
  getUser: () => null,
  hasRole: (role: Roles) => false,
  loginUser: (token: string) => {
    throw new Error("no implemented");
  },
  loginOut: () => {},
};

const SESSION_KEY = "paletyuser";

export const AuthContext = createContext<AuthContextValue>(initValue);

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userState, setUser] = useState<User | null>(null);

  const fetchMe = useCallback(async () => {
    const response = await apiClient.get<UserDTO>("/me");
    const me = response.data;
    const user = {
      ...me,
      createTime: new Date(me.createTime),
      lastLogin: me.lastLogin ? new Date(me.lastLogin) : null,
    };
    setUser(user);
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(user));
    return user;
  }, [setUser]);

  const getUser = useCallback(() => {
    if (!userState) {
      const userJson = sessionStorage.getItem(SESSION_KEY);
      if (userJson) {
        return JSON.parse(userJson);
      } else {
        fetchMe();
        return null;
      }
    }
    return userState;
  }, [fetchMe, userState]);

  const loginUser = useCallback(
    (token: string) => {
      saveToken(token);
      return fetchMe();
    },
    [fetchMe]
  );

  const loginOut = useCallback(() => {
    clearToken();
    setUser(null);
  }, []);

  const hasRole = useCallback(
    (role: Roles) => {
      const user = getUser();
      if (!user) {
        return false;
      }
      return hasRoleAuth(user, role);
    },
    [getUser]
  );

  useEffect(() => {
    if (document.location.pathname !== "/login") {
      fetchMe();
    }
  }, [fetchMe]);

  return (
    <AuthContext.Provider value={{ getUser, hasRole, loginUser, loginOut }}>
      {children}
    </AuthContext.Provider>
  );
};
