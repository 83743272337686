import format from "date-fns/format";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchReportTransportDirectToManufacturerStat,
  fetchReportTransportToWarehouseStat,
} from "../../../apiClient/reportClient";
import { DATE_USER_FORMAT } from "../../../interface/Date";
import { TransferFromReport } from "../../../interface/Report";
import { useManufacturerReportData } from "../hooks/useManufacturerReportData";
import { useWarehouseReportData } from "../hooks/useWarehouseReportData";
import { ManufacturerReport } from "./ManufacturerReport";
import { TransportFromStats } from "./TransportFromStats";
import { TransportToStats } from "./TransportToStats";
import { WarehouseReport } from "./WarehouseReport";

const ReportPdf = () => {
  const { dateFrom: dateFromParam, dateTo: dateToParam, type } = useParams();
  const dateFrom = useMemo(
    () => new Date(dateFromParam as string),
    [dateFromParam]
  );
  const dateTo = useMemo(() => new Date(dateToParam as string), [dateToParam]);

  const [print, setPrint] = useState(true);

  const [transportFromStat, setTransportFromStat] =
    useState<TransferFromReport | null>(null);
  const [transportDirectFromStat, setTransportDirectFromStat] =
    useState<TransferFromReport | null>(null);
  const {
    countTransferToManufacturer,
    countDisposableReceived,
    countDisposableRepair,
    countRepaired,
  } = useWarehouseReportData(dateFrom, dateTo);
  const { countTransferFromStories, transportToStat } =
    useManufacturerReportData(dateFrom, dateTo);

  useEffect(() => {
    fetchReportTransportToWarehouseStat({ dateFrom, dateTo }).then((data) => {
      setTransportFromStat(data);
    });
    fetchReportTransportDirectToManufacturerStat({ dateFrom, dateTo }).then(
      (data) => {
        setTransportDirectFromStat(data);
      }
    );
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (
      transportFromStat &&
      transportDirectFromStat &&
      countTransferToManufacturer != null &&
      countDisposableReceived != null &&
      countDisposableRepair != null &&
      countRepaired != null &&
      countTransferFromStories != null &&
      transportToStat &&
      print
    ) {
      window.print();
      setPrint(false);
    }
  }, [
    countDisposableReceived,
    countDisposableRepair,
    countRepaired,
    countTransferFromStories,
    countTransferToManufacturer,
    print,
    transportDirectFromStat,
    transportFromStat,
    transportToStat,
  ]);

  return (
    <>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
        @page { size: landscape letter; orientation: landscape; }
      `,
        }}
      />
      {(type === "ALL" || type === "WAREHOUSE") && (
        <>
          <h3>
            {format(dateFrom, DATE_USER_FORMAT)} -{" "}
            {format(dateTo, DATE_USER_FORMAT)}
          </h3>
          <div className="mb-5">
            <h2>Magazyn</h2>
            <WarehouseReport
              countTransferToManufacturer={countTransferToManufacturer}
              countDisposableReceived={countDisposableReceived}
              countDisposableRepair={countDisposableRepair}
              countRepaired={countRepaired}
            />
            <TransportFromStats transportStat={transportFromStat} />
          </div>
          {type === "ALL" && <div style={{ breakBefore: "page" }}>&nbsp;</div>}
        </>
      )}

      {(type === "ALL" || type === "MANUFACTURER") && (
        <>
          <h3>
            {format(dateFrom, DATE_USER_FORMAT)} -{" "}
            {format(dateTo, DATE_USER_FORMAT)}
          </h3>
          <div className="mb-5">
            <h2>Producent</h2>
            <ManufacturerReport
              countTransferFromStories={countTransferFromStories}
            />
            <TransportToStats transportToStat={transportToStat} />
          </div>

          {type === "ALL" && <div style={{ breakBefore: "page" }}>&nbsp;</div>}
        </>
      )}

      {(type === "ALL" || type === "TRANSPORT") && (
        <>
          <h3>
            {format(dateFrom, DATE_USER_FORMAT)} -{" "}
            {format(dateTo, DATE_USER_FORMAT)}
          </h3>
          <div className="mb-5">
            <h2>Transport</h2>
            <h3>Transporty z magazynów producenta do magazynu</h3>
            <TransportFromStats transportStat={transportFromStat} />

            <h3>
              Transporty z magazynów producenta bezpośrednio do producenta
            </h3>
            <TransportFromStats transportStat={transportDirectFromStat} />
          </div>
        </>
      )}
    </>
  );
};

export default ReportPdf;
