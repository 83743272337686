import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";

interface ModalContextProps {
  closeModal: () => void;
  setOpenModal: (modelName: string, payload?: unknown) => void;
  openModal: string | false;
  modalPayload?: unknown;
  isModalDone: boolean;
  setModalDone: () => void;
}

const initValue: ModalContextProps = {
  closeModal: () => {},
  setOpenModal: (modelName: string) => {},
  openModal: false,
  modalPayload: null,
  isModalDone: true,
  setModalDone: () => {},
};

export const ModalContext = createContext<ModalContextProps>(initValue);

export const ModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openModal, setOpen] = useState<string | false>(false);
  const [isDone, setDone] = useState<boolean>(false);
  const [modalPayload, setModalPayload] = useState<unknown>();

  const closeModal = useCallback(() => {
    setOpen(false);
    setModalPayload(null);
  }, [setOpen, setModalPayload]);

  const setOpenModal = useCallback(
    (modalName: string, payload?: unknown) => {
      setOpen(modalName);
      setDone(false);
      if (payload) {
        setModalPayload(payload);
      }
    },
    [setOpen, setModalPayload]
  );

  const setModalDone = useCallback(() => {
    setDone(true);
    closeModal();
  }, [closeModal, setDone]);

  return (
    <ModalContext.Provider
      value={{
        closeModal,
        setOpenModal,
        openModal,
        modalPayload,
        isModalDone: isDone,
        setModalDone,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
