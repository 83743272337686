import { MessageContextProvider } from "./common/context/MessageContext";
import { AuthContextProvider } from "./common/context/AuthContext";
import AppRouter from "./AppRouter";

function App() {
  return (
    <MessageContextProvider>
      <AuthContextProvider>
        <AppRouter />
      </AuthContextProvider>
    </MessageContextProvider>
  );
}

export default App;
