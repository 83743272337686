import axios from "axios";
import { clearToken, getToken } from "../auth";
import { API_URL } from "../config";

const apiClient = axios.create({
  baseURL: API_URL,
});

apiClient.interceptors.request.use((config) => {
  config.headers["Authorization"] = getToken();
  return config;
});

apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      clearToken()
      document.location.href = "/login";
      return;
    }
    return Promise.reject(error);
  }
);

// todo interceptor for http status 401

export { apiClient };
