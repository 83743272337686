import { StoreType } from "./Store";

export enum TransferStatus {
  MANUFACTURER_NEED = "MANUFACTURER_NEED",
  SAVED = "SAVED",
  WAITING_FOR_CHECK = "WAITING_FOR_CHECK",
}

export enum TransportType {
  TRANSPORT_OWN = "OWN",
  TRANSPORT_OUTSIDE = "OUTSIDE",
  TRANSPORT_PAULA = "PAULA",
  TRANSPORT_MARPOL = "MARPOL",
  TRANSPORT_OTHER = "OTHER",
}

export const TransportTypeName = {
  [TransportType.TRANSPORT_OWN]: "Własny",
  [TransportType.TRANSPORT_OUTSIDE]: "Kurier",
  [TransportType.TRANSPORT_PAULA]: "Paula",
  [TransportType.TRANSPORT_MARPOL]: "Marpol",
  [TransportType.TRANSPORT_OTHER]: "Inny",
}
export interface WarehouseDTO {
  id: number;
  amount: number;
  createTime: string;
  deleted: boolean;
  lastChange: string | null;
  name: string;
  type: StoreType;
}

export type ParentTransferDTO = Omit<TransferDTO, "warehouseTo" | "parent">; 

export interface TransferDTO {
  id: number;
  amount: number;
  createTime: string;
  parentId: number | null;
  status: TransferStatus;
  transferDate: string;
  transportName: TransportType;
  warehouseFromId: number;
  warehouseFrom: WarehouseDTO;
  warehouseToId: number;
  warehouseTo: WarehouseDTO;
  parent: ParentTransferDTO | null;
  documentNumber: string | null;
}

export interface TransferWithChildrenDTO extends TransferDTO {
  children: TransferDTO[] | null;
}

export interface Warehouse {
  id: number;
  amount: number;
  createTime: Date;
  deleted: boolean;
  lastChange: Date | null;
  name: string;
  type: StoreType;
}

export type ParentTransfer = Omit<Transfer, "warehouseTo" | "parent">; 

export interface Transfer {
  id: number;
  amount: number;
  createTime: Date;
  parentId: number | null;
  status: TransferStatus;
  transferDate: Date;
  transportName: TransportType;
  warehouseFromId: number;
  warehouseFrom: Warehouse;
  warehouseToId: number;
  warehouseTo: Warehouse;
  parent: ParentTransfer | null;
  documentNumber: string | null;
}

export interface TransferWithChildren extends Transfer {
  children: Transfer[] | null;
}

export interface TransferBaseResponseDTO<T> {
  count: number;
  limit: number;
  rows: T[];
}
export type TransferResponseDTO = TransferBaseResponseDTO<TransferDTO>;

export type TransferWithChildrenResponseDTO = TransferBaseResponseDTO<TransferWithChildrenDTO>;

export interface TransferBaseResponse<T> {
  count: number;
  limit: number;
  rows: T[];
}
export type TransferResponse = TransferBaseResponse<Transfer>;
export type TransferWithChildrenResponse = TransferBaseResponse<TransferWithChildren>;

export interface WarehouseAcceptPalletsData {
  transferId: number;
  workshop: number;
  dump: number;
  date: Date;
}

export interface AddTransferData {
  amount: number;
  transport: TransportType;
  date: Date;
  storeFromId: number;
  storeToId: number;
  documentNumber?: string;
}

export interface EditTransferData {
  amount: number;
  date: Date;
  documentNumber?: string;
}
