import { redirect } from "react-router-dom";
import { Roles, User } from "./interface/User";

const STORAGE_TOKEN_KEY = "token";

export const getToken = () => {
  return localStorage.getItem(STORAGE_TOKEN_KEY);
};

export const saveToken = (token: string) => {
  return localStorage.setItem(STORAGE_TOKEN_KEY, token);
};

export const clearToken = () => {
  localStorage.removeItem(STORAGE_TOKEN_KEY);
};

export const auth = async () => {
  const token = getToken();
  if (!token) {
    return redirect("/login");
  }
  // TODO get me if token invalid go to login page
  return null;
};

export const hasRole = (user: User, role: Roles) => {
  if (user.roles.includes(Roles.ADMIN)) {
    return true;
  }
  return user.roles.includes(role);
};
