export interface UserDTO {
  id: number;
  userName: string;
  createTime: string;
  lastLogin: string | null;
  roles: Roles[];
}

export interface User {
  id: number;
  userName: string;
  createTime: Date;
  lastLogin: Date | null;
  roles: Roles[];
}

export enum Roles {
  ADMIN = "admin",
  WAREHOUSE = "warehouse",
  TRANSPORT = "transport",
  MANUFACTURER = "manufacturer",
  REPORT = "report",
}
