import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";

interface MessageContextValue {
  errorMessage: string | null;
  setErrorMessage: (message: string) => void;
  clearErrorMessage: () => void;
  successMessage: string | null;
  setSuccessMessage: (message: string) => void;
  clearSuccessMessage: () => void;
}

const initValue: MessageContextValue = {
  errorMessage: null,
  setErrorMessage: (message: string) => {},
  clearErrorMessage: () => {},
  successMessage: null,
  setSuccessMessage: (message: string) => {},
  clearSuccessMessage: () => {},
};

export const MessageContext = createContext<MessageContextValue>(initValue);

export const MessageContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const setErrorMessage = useCallback(
    (message: string) => {
      setError(message);
    },
    [setError]
  );

  const clearErrorMessage = useCallback(() => {
    setError(null);
  }, [setError]);

  const clearSuccessMessage = useCallback(() => {
    setSuccess(null);
  }, [setSuccess]);

  const setSuccessMessage = useCallback(
    (message: string) => {
      setSuccess(message);
      setTimeout(() => {
        clearSuccessMessage();
      }, 4000);
    },
    [setSuccess, clearSuccessMessage]
  );

  return (
    <MessageContext.Provider
      value={{
        errorMessage: error,
        setErrorMessage,
        clearErrorMessage,
        successMessage: success,
        setSuccessMessage,
        clearSuccessMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
