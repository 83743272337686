import { useContext } from "react";
import { MessageContext } from "../../../common/context/MessageContext";

export const Success = () => {
  const { successMessage, clearSuccessMessage } = useContext(MessageContext);
  return successMessage ? (
    <div className="toast-container position-fixed bottom-0 end-0 p-3">
      <div
        className="toast align-items-center text-bg-success border-0 show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="d-flex">
          <div className="toast-body">{successMessage}</div>
          <button
            onClick={clearSuccessMessage}
            type="button"
            className="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
