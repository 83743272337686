import { FC } from "react";

export enum LoginErrorType {
  INCORRECT_LOGIN_PASSWORD = "INCORRECT_LOGIN_PASSWORD",
}

export const LoginError: FC<{ error: LoginErrorType }> = ({ error }) => {
  return (
    <div className="alert alert-danger">
      {error === LoginErrorType.INCORRECT_LOGIN_PASSWORD &&
        "Niepoprawny login lub hasło"}
    </div>
  );
};
