import { AxiosError } from "axios";
import { FormEvent, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../apiClient/apiClient";
import { AuthContext } from "../../../common/context/AuthContext";
import { MessageContext } from "../../../common/context/MessageContext";
import { Roles } from "../../../interface/User";
import { Error } from "../../error/components/Error";
import { LoginError, LoginErrorType } from "./LoginError";

const LoginPage = () => {
  const { setErrorMessage } = useContext(MessageContext);
  const { loginUser } = useContext(AuthContext);
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<LoginErrorType | null>(null);
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setError(null);
      try {
        const {
          data: { token },
        } = await apiClient.post("/login", {
          login,
          password,
        });
        const me = await loginUser(token);
        apiClient.defaults.headers.common["Authorization"] = token;

        if (me.roles.includes(Roles.ADMIN)) {
          navigate("/warehouse");
        } else {
          navigate(`/${me.roles[0]}`);
        }
      } catch (e) {
        if ((e as AxiosError).response?.status === 404) {
          setError(LoginErrorType.INCORRECT_LOGIN_PASSWORD);
        } else {
          setErrorMessage("problem z połączeniem");
        }
      }
    },
    [login, loginUser, navigate, password, setErrorMessage]
  );

  return (
    <>
      <Error />
      <main className="login-container">
        <form className="form-signin" onSubmit={onSubmit}>
          <img
            className="mb-5"
            src="/logo-no-background-small.png"
            alt=""
            width="200"
          />
          <h1 className="h3 mb-3 fw-normal">Zaloguj się</h1>

          {error && <LoginError error={error} />}

          <div className="form-floating">
            <input
              type="text"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              className="form-control"
              id="floatingInput"
              placeholder="login"
            />
            <label htmlFor="floatingInput">Login</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="floatingPassword"
              placeholder="hasło"
            />
            <label htmlFor="floatingPassword">hasło</label>
          </div>

          <button className="w-100 btn btn-lg btn-primary" type="submit">
            Zaloguj
          </button>
          <p className="mt-5 mb-3 text-muted">
            Copyright &copy; 2015-{new Date().getFullYear()} by Grzegorz.
            <br />
            All Rights Reserved.
          </p>
        </form>
      </main>
    </>
  );
};

export default LoginPage;
