import { FC } from "react";

interface ManufacturerReportProps {
  countTransferFromStories: number | null;
}

export const ManufacturerReport: FC<ManufacturerReportProps> = ({
  countTransferFromStories,
}) => {

  return (
    <table className="table table-bordered amount">
      <tbody>
        <tr>
          <th>Przywiezione z magazynów</th>
          <td>{countTransferFromStories}</td>
        </tr>
      </tbody>
    </table>
  );
};
