import { useEffect, useState } from "react";
import { fetchReportPalletsAmount } from "../../../apiClient/reportClient";
import { StoreType } from "../../../interface/Store";

export const useWarehouseReportData = (dateFrom: Date, dateTo: Date) => {
  const [countTransferToManufacturer, setCountTransferToManufacturer] =
    useState<number | null>(null);
  const [countDisposableReceived, setCountDisposableReceived] = useState<number | null>(null);
  const [countDisposableRepair, setCountDisposableRepair] = useState<number | null>(null);
  const [countRepaired, setCountRepaired] = useState<number | null>(null);

  useEffect(() => {
    fetchReportPalletsAmount({
      storeFrom: StoreType.WAREHOUSE,
      storeTo: StoreType.STORE_MAIN_MANUFACTURER,
      dateFrom,
      dateTo,
    }).then((amount) => {
      setCountTransferToManufacturer(amount);
    });

    fetchReportPalletsAmount({
      storeFrom: StoreType.WORKSHOP,
      storeTo: StoreType.WAREHOUSE,
      dateFrom,
      dateTo,
    }).then((amount) => {
      setCountRepaired(amount);
    });

    fetchReportPalletsAmount({
      storeFrom: StoreType.MANUFACTURER_PALLETS_DISPOSABLE,
      storeTo: StoreType.WAREHOUSE_PALLETS_DISPOSABLE,
      dateFrom,
      dateTo,
    }).then((count) => {
      setCountDisposableReceived(count)
    });
    fetchReportPalletsAmount({
      storeFrom: StoreType.MANUFACTURER_PALLETS_DISPOSABLE,
      storeTo: StoreType.WORKSHOP,
      dateFrom,
      dateTo,
    }).then((count) => {
      setCountDisposableRepair(count);
    });

  }, [dateFrom, dateTo]);

  return { countTransferToManufacturer, countDisposableReceived, countDisposableRepair, countRepaired };
};
