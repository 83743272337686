export enum StoreType {
  STORE_MANUFACTURER = "STORE_MANUFACTURER",
  WAREHOUSE = "WAREHOUSE",
  STORE_MAIN_MANUFACTURER = "STORE_MAIN_MANUFACTURER",
  WORKSHOP = "WORKSHOP",
  DUMP = "DUMP",
  BUY = "BUY",

  WAREHOUSE_PALLETS_DISPOSABLE = "WAREHOUSE_PALLETS_DISPOSABLE",
  WAREHOUSE_PALLETS_USA = "WAREHOUSE_PALLETS_USA",
  WAREHOUSE_PALLETS_HALF = "WAREHOUSE_PALLETS_HALF",
  WAREHOUSE_PALLETS_SPECIAL = "WAREHOUSE_PALLETS_SPECIAL",
  WAREHOUSE_PALLETS_EURO_MH = "WAREHOUSE_PALLETS_EURO_MH",
  WAREHOUSE_PALLETS_DISPOSABLE_MH = "WAREHOUSE_PALLETS_DISPOSABLE_MH",
  WAREHOUSE_PALLETS_USA_BLUE_MH = "WAREHOUSE_PALLETS_USA_BLUE_MH",
  WAREHOUSE_PALLETS_USA_MH = "WAREHOUSE_PALLETS_USA_MH",
  WAREHOUSE_PALLETS_SPECIAL_MH = "WAREHOUSE_PALLETS_SPECIAL_MH",
  MANUFACTURER_PALLETS_DISPOSABLE = "MANUFACTURER_PALLETS_DISPOSABLE",
  MANUFACTURER_PALLETS_USA = "MANUFACTURER_PALLETS_USA",
  MANUFACTURER_PALLETS_HALF = "MANUFACTURER_PALLETS_HALF",
  MANUFACTURER_PALLETS_SPECIAL = "MANUFACTURER_PALLETS_SPECIAL",
  MANUFACTURER_PALLETS_EURO_MH = "MANUFACTURER_PALLETS_EURO_MH",
  MANUFACTURER_PALLETS_DISPOSABLE_MH = "MANUFACTURER_PALLETS_DISPOSABLE_MH",
  MANUFACTURER_PALLETS_USA_BLUE_MH = "MANUFACTURER_PALLETS_USA_BLUE_MH",
  MANUFACTURER_PALLETS_USA_MH = "MANUFACTURER_PALLETS_USA_MH",
  MANUFACTURER_PALLETS_SPECIAL_MH = "MANUFACTURER_PALLETS_SPECIAL_MH",

  START = "START",
}

export interface StoreDTO {
  id: number;
  name: string;
  amount: number;
  type: StoreType;
  createTime: string;
  deleted: boolean;
  notice: string;
}

export interface Store {
  id: number;
  name: string;
  amount: number;
  type: StoreType;
  createTime: Date;
  deleted: boolean;
  notice: string;
}

export interface StoreAdd {
  name: string;
  notice: string;
}
