import React, { Suspense, useCallback, useContext, useMemo } from "react";
import LoginPage from "./feature/login/components/LoginPage";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { Layout } from "./feature/layout/components/Layout";
import { auth } from "./auth";
import { Loader } from "./common/components/Loader";
import { AuthContext } from "./common/context/AuthContext";
import { Roles } from "./interface/User";
import ReportPdf from "./feature/report/components/ReportPdf";

const WarehousePage = React.lazy(
  () => import("./feature/warehouse/components/WarehousePage")
);
const WaitingForCheckTransfers = React.lazy(
  () => import("./feature/warehouse/components/WaitingForCheckTransfers")
);
const Transfers = React.lazy(
  () => import("./feature/warehouse/components/Transfers")
);
const TransfersFromManufacturer = React.lazy(
  () => import("./feature/warehouse/components/TransfersFromManufacturer")
);
const ManufacturerPage = React.lazy(
  () => import("./feature/manufacturer/components/ManufacturerPage")
);
const TransportPage = React.lazy(
  () => import("./feature/transport/components/TransportPage")
);
const HistoryPage = React.lazy(
  () => import("./feature/history/components/HistoryPage")
);
const ReportPage = React.lazy(
  () => import("./feature/report/components/ReportPage")
);

function AppRouter() {
  const { hasRole } = useContext(AuthContext);

  const hasRoleRouting = useCallback(
    (role: Roles) => {
      if (hasRole(role)) {
        return null;
      }
      return redirect("/");
    },
    [hasRole]
  );

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/login",
          element: <LoginPage />,
        },
        {
          path: "/pdf/:type/:dateFrom/:dateTo",
          element: <ReportPdf />,
        },
        {
          path: "/",
          element: <Layout />,
          loader: auth,
          children: [
            {
              path: "",
              element: <h2>Home</h2>,
            },
            {
              path: "/warehouse",
              element: (
                <Suspense fallback={<Loader />}>
                  <WarehousePage />
                </Suspense>
              ),
              loader: () => hasRoleRouting(Roles.WAREHOUSE),
              children: [
                {
                  path: "",
                  element: (
                    <Suspense fallback={<Loader />}>
                      <WaitingForCheckTransfers />
                    </Suspense>
                  ),
                },
                {
                  path: "transfers/:storeFrom/:storeTo",
                  element: (
                    <Suspense fallback={<Loader />}>
                      <Transfers />
                    </Suspense>
                  ),
                },
                {
                  path: "transfers-from-manufacturer",
                  element: (
                    <Suspense fallback={<Loader />}>
                      <TransfersFromManufacturer />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: "/manufacturer",
              element: (
                <Suspense fallback={<Loader />}>
                  <ManufacturerPage />
                </Suspense>
              ),
              loader: () => hasRoleRouting(Roles.MANUFACTURER),
            },
            {
              path: "/transport",
              element: (
                <Suspense fallback={<Loader />}>
                  <TransportPage />
                </Suspense>
              ),
              loader: () => hasRoleRouting(Roles.TRANSPORT),
            },
            {
              path: "/report",
              element: (
                <Suspense fallback={<Loader />}>
                  <ReportPage />
                </Suspense>
              ),
              loader: () => hasRoleRouting(Roles.REPORT),
            },
            {
              path: "/history",
              element: (
                <Suspense fallback={<Loader />}>
                  <HistoryPage />
                </Suspense>
              ),
              loader: () => hasRoleRouting(Roles.ADMIN),
            },
          ],
        },
      ]),
    [hasRoleRouting]
  );

  return <RouterProvider router={router} />;
}

export default AppRouter;
