import { resetTime } from "../common/utils/DateUtils";
import { TransferFromReport, TransferToReport } from "../interface/Report";
import { StoreType } from "../interface/Store";
import { apiClient } from "./apiClient";

export async function fetchReportPalletsAmount({
  storeFrom,
  storeTo,
  dateFrom,
  dateTo,
}: {
  storeFrom: StoreType;
  storeTo: StoreType;
  dateFrom: Date;
  dateTo: Date;
}) {
  const response = await apiClient.get<{amount: number}>("/reports/pallet-amount", {
    params: {
      store_from: storeFrom,
      store_to: storeTo,
      date_from: resetTime(dateFrom),
      date_to: resetTime(dateTo),
    },
  });
  return response.data.amount;
}

export async function fetchReportTransportToWarehouseStat({
  dateFrom,
  dateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
}) {
  const response = await apiClient.get<TransferFromReport>("/reports/transport-from-stats", {
    params: {
      store_from: StoreType.STORE_MANUFACTURER,
      store_to: StoreType.WAREHOUSE,
      date_from: resetTime(dateFrom),
      date_to: resetTime(dateTo),
    },
  });
  return response.data;
}

export async function fetchReportTransportDirectToManufacturerStat({
  dateFrom,
  dateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
}) {
  const response = await apiClient.get<TransferFromReport>("/reports/transport-from-stats", {
    params: {
      store_from: StoreType.STORE_MANUFACTURER,
      store_to: StoreType.STORE_MAIN_MANUFACTURER,
      date_from: resetTime(dateFrom),
      date_to: resetTime(dateTo),
    },
  });
  return response.data;
}

export async function fetchReportTransportToManufacturesStoresStat({
  dateFrom,
  dateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
}) {
  const response = await apiClient.get<TransferToReport>("/reports/transport-to-stats", {
    params: {
      store_from: StoreType.STORE_MAIN_MANUFACTURER,
      store_to: StoreType.STORE_MANUFACTURER,
      date_from: resetTime(dateFrom),
      date_to: resetTime(dateTo),
    },
  });
  return response.data;
}
