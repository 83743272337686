import { useEffect, useState } from "react";
import {
  fetchReportPalletsAmount,
  fetchReportTransportToManufacturesStoresStat,
} from "../../../apiClient/reportClient";
import { TransferToReport } from "../../../interface/Report";
import { StoreType } from "../../../interface/Store";

export const useManufacturerReportData = (dateFrom: Date, dateTo: Date) => {
  const [countTransferFromStories, setCountTransferFromStories] = useState<
    number | null
  >(null);
  const [transportToStat, setTransportToStat] =
    useState<TransferToReport | null>(null);

  useEffect(() => {
    fetchReportPalletsAmount({
      storeFrom: StoreType.STORE_MANUFACTURER,
      storeTo: StoreType.WAREHOUSE,
      dateFrom,
      dateTo,
    }).then((amount) => {
      setCountTransferFromStories(amount);
    });

    fetchReportTransportToManufacturesStoresStat({ dateFrom, dateTo }).then(
      (data) => {
        setTransportToStat(data);
      }
    );
  }, [dateFrom, dateTo]);

  return { countTransferFromStories, transportToStat };
};
