import { useCallback, useContext } from "react";
import {
  Outlet,
  NavLink,
  useNavigate,
  ScrollRestoration,
} from "react-router-dom";
import { AuthContext } from "../../../common/context/AuthContext";
import { ModalContextProvider } from "../../../common/context/ModalContext";
import { Roles } from "../../../interface/User";
import { Error } from "../../error/components/Error";
import { Success } from "../../error/components/Success";

export const Layout = () => {
  const navigate = useNavigate();
  const { hasRole, loginOut } = useContext(AuthContext);

  const logout = useCallback(() => {
    loginOut();
    navigate("/login");
  }, [loginOut, navigate]);

  const linkClassName = useCallback(
    (isActive: boolean) =>
      `${isActive ? "text-secondary" : "text-white"} nav-link px-2`,
    []
  );

  return (
    <>
      <header className="p-3 text-bg-dark">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => linkClassName(isActive)}
                >
                  Home
                </NavLink>
              </li>
              {hasRole(Roles.WAREHOUSE) && (
                <li>
                  <NavLink
                    to="/warehouse"
                    className={({ isActive }) => linkClassName(isActive)}
                  >
                    Magazyn
                  </NavLink>
                </li>
              )}
              {hasRole(Roles.MANUFACTURER) && (
                <li>
                  <NavLink
                    to="/manufacturer"
                    className={({ isActive }) => linkClassName(isActive)}
                  >
                    Producent
                  </NavLink>
                </li>
              )}
              {hasRole(Roles.TRANSPORT) && (
                <li>
                  <NavLink
                    to="/transport"
                    className={({ isActive }) => linkClassName(isActive)}
                  >
                    Transport
                  </NavLink>
                </li>
              )}
              {hasRole(Roles.REPORT) && (
                <li>
                  <NavLink
                    to="/report"
                    className={({ isActive }) => linkClassName(isActive)}
                  >
                    Raport
                  </NavLink>
                </li>
              )}
              {hasRole(Roles.ADMIN) && (
                <li>
                  <NavLink
                    to="/history"
                    className={({ isActive }) => linkClassName(isActive)}
                  >
                    Historia Zmian
                  </NavLink>
                </li>
              )}
            </ul>

            <div className="text-end">
              <button
                onClick={logout}
                type="button"
                className="btn btn-warning"
              >
                Wyloguj
              </button>
            </div>
          </div>
        </div>
      </header>
      <ModalContextProvider>
        <div className="container pt-3">
          <Error />
          <Success />
          <Outlet />
        </div>
      </ModalContextProvider>
      <ScrollRestoration />
    </>
  );
};
